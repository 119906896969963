import React from 'react'
import BgBook from '../assets/img/photo-1473492201326-7c01dd2e596b.webp'

export default function Testimonial() {
  return (
    <div className="bg-white py-5 lg:py-10">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative py-24 px-8 bg-blue-500 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
            <img
              src={BgBook}
              alt=""
              className="w-full h-full object-cover" loading="lazy"
            />
          </div>
          <div className="relative lg:col-span-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 870.04 860.08" className='h-10 w-auto'>
              <path className="fill-[#b3b3b3]" d="M189,685h11c1.09,2,3,1,4.43.94,21.26-.21,42.36,1.48,63.47,3.94a481,481,0,0,1,99.43,21.92c59.16,20,116.18,44.7,167.92,80.28,18.26,12.56,36.63,25.26,52,41.3C593.36,839.82,602,845,604,855v5H277a9.82,9.82,0,0,0-1.07-1.59Q192.58,775.57,109.2,692.76A6.11,6.11,0,0,0,108,692v-1c16.86-3,34-3.1,50.94-4.7C169,685.36,179.08,687.5,189,685Z" />
              <path className="fill-[#1e7fc1]" d="M719,723c-.26,1.27,1.08,3.61-2,3-1.89-1.54-3.7-3.21-5.7-4.6a694.63,694.63,0,0,0-124-68.69,709.65,709.65,0,0,0-105-35.72c-30.23-7.71-60.72-14.07-91.85-17-17.33-1.65-34.64-4.28-52-4.73-23.41-.62-46.87.15-70.29.87-12.49.38-25,1.44-37.4,3-18.16,2.26-36.44,4.28-54.3,8.12-24.79,5.32-49.36,11.81-73.79,18.66-13.66,3.83-26.84,9.41-40.24,14.19-3,1.06-5.84,2.07-8.71-.74Q27,613.09,0,587V366c22.65-4.95,45.14-10.78,68-14.65,33.51-5.69,67.39-7.27,101.43-6.47A587.24,587.24,0,0,1,272.3,356.37a615.35,615.35,0,0,1,180.11,66.89c73,41,135.58,94.14,186.71,160.53a566.26,566.26,0,0,1,70.26,118.26C712.47,709.08,715.79,716,719,723Z" />
              <path className="fill-[#6b9b46]" d="M870,264q0,60.26,0,120.5c0,2.18.42,4.42-3,3.5-4.39-5.63-9.29-10.94-13-17-8.3-13.3-16.69-26.62-23.79-40.57-7.59-14.9-14-30.44-20.46-45.89-7.1-17-14-34-20.35-51.33-4.08-11.18-6.94-22.82-10.35-34.25V185h4c8.18,6.4,16.87,12.24,24.42,19.3,16,15,31.32,30.65,47.06,45.9C859.44,255,864.81,259.41,870,264Z" />
              <path className="fill-[#e75141]" d="M769,406l-2,1c-5.68-5.31-11.31-10.69-17.06-15.93a859.1,859.1,0,0,0-116.71-89.5,697.06,697.06,0,0,0-102.55-54.2c-50.09-21.2-101.93-36.49-155.81-44.74a684.38,684.38,0,0,0-95.13-8C241,194.11,202.32,196,164,202c-23.67,3.72-47.22,8.2-70.75,12.72C81.09,217,69.08,220.21,57,223v-2c4.48-4.63,8.93-9.28,13.43-13.88q19.57-20,39.18-39.9Q147.32,128.64,185,90q35.44-36.26,70.94-72.47C261.44,11.86,266.64,5.86,272,0H593c4.82,4.92,9.55,9.94,14.48,14.75,15.16,14.75,30.31,29.5,45.61,44.11,21.6,20.65,43.34,41.16,65,61.75a118.85,118.85,0,0,1,8.43,8.52c1.19,1.38,2.71,3.86,2.22,5.11-5.48,14-3.25,28.6-3.62,42.94a407.84,407.84,0,0,0,.9,42.39c1.43,18.33,3.22,36.7,6.25,54.82C738,308.8,747,342.47,758,375.56,761.45,385.79,765.33,395.86,769,406Z" />
            </svg>
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl">
                Athena has completely transformed how we interact with customers. We've seen business growth, higher
                customer satisfaction, and reduced churn.
              </p>
              <footer className="mt-6">
                <p className="flex flex-col font-medium">
                  <span>Ndirangu Njoroge</span>
                  <span>Director, Dosan Ltd.</span>
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}
