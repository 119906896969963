import React from "react";
import { Nav } from "./Navigation";
import Hello from "../assets/img/adam-solomon-WHUDOzd5IYU-unsplash-scaled.webp";
import Lappy from "../assets/img/photo-1460925895917-afdab827c52f.webp";
import About from "../assets/img/photo-1455849318743-b2233052fcff.webp";

const bg = {
  about: {
    header: {
      name: "About Us",
      img: About,
      tag: "We focus on leveraging our infrastructure and presence to support a wide range of fast-moving businesses and new technologies. Over the years, Athena has grown into an advanced solutions provider focused on serving customers and creating value through the long-term relationships we build.",
    },
  },
  services: {
    header: {
      name: "What we offer",
      img: Lappy,
      tag: "We provide a wide array of services as solutions",
    },
  },
  contact: {
    header: {
      name: "Contact Us",
      img: Hello,
      tag: "We'd Love To Hear From You",
    },
  },
};

export default function PageHeader({ page }) {
  const { name, img, tag } = bg[page].header;
  // console.log(bg[page]);
  return (
    <header className="relative bg-blue-800">
      <div className="relative bg-blue-800">
        <div className="absolute inset-0">
          <img className="w-full h-full object-cover" src={img} alt="" />
          <div
            className="absolute inset-0 bg-blue-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <Nav />
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            {name}
          </h1>
          <p className="mt-6 text-xl text-indigo-100 max-w-3xl">{tag}</p>
        </div>
      </div>
    </header>
  );
}
