import React from 'react'
import { Decisions } from '../../components/Illustrations'

export default function BI() {
  return (
    <div className="bg-blue-50">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                We built our business on great customer service
              </h2>
              <p className="mt-4 text-gray-500">
                The term Business intelligence refers to the software that is designed to analyze, retrieve, and deliver actionable data, assisting organizations to make tactical and critical business decisions. The organization gathers the information from internal or external resources, make analysis on the information, execute queries and provides comprehensive intelligence reports to the business users about the business statics, which helps them in decision-making and strategic planning.
                This mainly focuses on making an efficient business decision that helps an organization to improve operational effectiveness, increase revenues, and achieve competitive benefits among business rivals. A combination of data management, analysis, and various tool & methodologies are being used by businesses to access the business’s insights for improved efficiencies and higher profitability
              </p>
            </div>
            <div className="aspect-w-4 aspect-h-4">
              <Decisions className="object-center object-cover" />
            </div>
          </div>

          {/* <ServiceIncentives /> */}
        </div>
      </div>
    </div>
  )
}
