// import { ClipboardCheckIcon } from "@heroicons/react/24/outline";
import { ClipboardIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { Nav } from "../components/Navigation";

const legalNavigation = [
  { name: 'Privacy Policy', href: '/privacy', icon: ShieldCheckIcon },
  { name: 'Terms & Conditions', href: '/terms', icon: ClipboardIcon },
]

const Legal = props => {
  console.log(props);
  return (
    <>
      {/* Background color split screen for large screens */}
      <div className="fixed top-0 left-0 w-full h-full bg-white" aria-hidden="true" />
      <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50" aria-hidden="true" />
      <div className="relative min-h-full flex flex-col">

        {/* Navbar */}
        <div className="flex-shrink-0 bg-blue-600 pb-6">
          <Nav />
        </div>

        {/* 2 column wrapper */}
        <div className="flex-grow w-full max-w-1xl mx-auto xl:px-8 lg:flex">
          {/* Left sidebar & main wrapper */}
          <div className="flex-1 min-w-0 bg-white xl:flex">
            {/* Side Menu */}
            <div className="xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
              <div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                <div className="flex items-center justify-between">
                  <div className="flex-1 space-y-8">
                    {/* Links info */}
                    <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                      {legalNavigation.map((item) => (
                        <div key={item.name} className="flex items-center space-x-2">
                          <item.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          <NavLink to={item.href}><span className="text-sm text-gray-500 font-medium">{item.name}</span></NavLink>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Display Text */}
            <div className="bg-white lg:min-w-0 lg:flex-1">
              <div className="p-1 border-b border-t border-blue-200 sm:pl-3 xl:border-t-0">
                <div className="flex items-center">
                  <h1 className="flex-1 text-lg font-bold">{props.title}</h1>
                </div>
              </div>
              <div className="relative z-0 h-[calc(100vh-160px)] px-3 overflow-y-auto sm:break-words">
                {props.children}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Legal;