import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react'

const pricing = {
  tiers: [
    {
      title: 'Basic',
      price: 25,
      frequency: ' - one off',
      description: 'A quick, basic web page',
      features: ['4 pages max', 'Basic analytics'],
      cta: 'One-off billing',
      mostPopular: false,
    },
    {
      title: 'Essential',
      price: 50,
      frequency: ' - one off',
      description: 'A dynamic website suitable for company',
      features: [
        '7 pages max',
        'SEO',
        'Analytics',
        '6 months support',
      ],
      cta: 'One-off billing',
      mostPopular: true,
    },
    {
      title: 'Enterprise',
      description: 'For more advanced websites',
      features: [
        'Multiple pages',
        'Custom features',
        'Advanced analytics',
        'Socials',
        'Custom integrations',
      ],
      cta: ' Depends on scope',
      mostPopular: false,
    },
  ],
}



export default function WebsitePricing() {
  return (
    <>
      {/* Page Header */}
      <div className="relative max-w-2xl mx-auto py-10 sm:px-6 lg:max-w-7xl lg:py-10 lg:px-8">
        <div className="relative">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:leading-none sm:tracking-tight">
            Pricing plans for businesses of all sizes
          </h1>
          <p className="mt-6 text-gray-500">
            Choose an affordable plan that's packed with the best features for engaging your audience, creating
            online presence, and driving your reach to customers.
          </p>
        </div>
      </div>
      {/* Pricing Section */}
      <section className="relative pb-10" aria-labelledby="pricing-heading">
        <h2 id="pricing-heading" className="sr-only">
          Pricing
        </h2>

        {/* Tiers */}
        <div className="max-w-2xl mx-auto px-4 space-y-12 sm:px-6 lg:max-w-7xl lg:space-y-0 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {pricing.tiers.map((tier) => (
            <div
              key={tier.title}
              className="relative p-8 border border-blue-600 rounded-2xl shadow-sm flex flex-col"
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
                {tier.mostPopular && (
                  <p className="absolute top-0 py-1.5 px-4 bg-gradient-to-r from-blue-700 to-blue-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                    Most popular
                  </p>
                )}
                <p className="mt-4 flex items-baseline text-gray-900">
                  <span className="text-4xl font-extrabold tracking-tight">{tier.price ? `Kes ${tier.price}K` : '-'}</span>
                  <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
                </p>
                <p className="mt-6 text-gray-500">{tier.description}</p>

                {/* Feature list */}
                <ul className="mt-6 space-y-6">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex">
                      <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
                      <span className="ml-3 text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <a
                  href="#a"
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-rose-500 text-white hover:bg-rose-600'
                      : 'bg-rose-50 text-rose-700 hover:bg-rose-100',
                    'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                  )}
                >
                  {tier.cta}
                </a> */}
            </div>
          ))}
        </div>
      </section>
    </>
  )
}