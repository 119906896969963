import React from 'react'
import { Mobile } from '../../components/animated/Splined'

export default function MobileApps() {
  return (
    <div className="bg-blue-50">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                Build great Mobile-based solutions with us
              </h2>
              <p className="mt-4 text-gray-500">
                The mobile app development services market is huge. To keep your business and product market-relevant, you need to create something that adds to the client's power of mobility, Keeping underlying functional purpose and intent of communication served well.</p>
              <p className="mt-4 text-gray-500">Athena Solutions aims to deliver just that – with the right resources, cost-effective services, and mobile app development services that fit your business and user needs.</p>

            </div>
            <div className="aspect-w-4 aspect-h-4">
              <Mobile className="object-center object-cover" />
            </div>
          </div>

          {/* <ServiceIncentives /> */}
        </div>
      </div>
    </div>
  )
}
