import React from 'react'
import { OrganizeData } from '../../components/Illustrations'

export default function ErpSolutions() {
  return (
    <div className="bg-blue-50">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                Let us help you integrate your business processes as one
              </h2>
              <p className="mt-4 text-gray-500">
                Enterprise resource planning (ERP) is a business process management software that allows an organization to use a system of integrated application modules (ERP) to manage the business and automate many back office functions related to technology, services and human resources.
              </p>
              <p className="mt-4 text-gray-500">
                If you’re thinking Finance, Supply Chain Management, Human Resource Management, Payroll, Procurement or any other process, we are ready to work with you whereas integrating all of the above and much more.
              </p>
            </div>
            <div className="aspect-w-4 aspect-h-4">
              <OrganizeData className="object-center object-cover" />
            </div>
          </div>

          {/* <ServiceIncentives /> */}
        </div>
      </div>
    </div>
  )
}
