import React from "react";
import { MainNav, FooterNav } from "../components/Navigation";
import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Services from "../components/Services";
import Testimonial from "../components/Testimonial";
// import Blog from "../components/Blog";
import CallToAction from "../components/CallToAction";
export default function Landing() {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <MainNav />
        <main>
          <Hero />

          <Feature />

          {/* Feature section with grid */}
          <Services pageFilter='landing' />

          {/* Testimonial section */}
          <Testimonial />

          {/* Blog section */}
          {/* <Blog /> */}

          {/* CTA Section */}
          <CallToAction />
          
        </main>
        <FooterNav />
      </div>
    </div>
  );
}