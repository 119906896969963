import React from "react";
import CallToAction from "../components/CallToAction";
import ClientLogoCloud from "../components/ClientLogoCloud";
import { FooterNav } from "../components/Navigation";
import PageHeader from "../components/PageHeader";
import Services from "../components/Services";

export default function Service() {
  
  return (
    <div className="bg-white">
      <PageHeader page={"services"} />
      <Services />
      <ClientLogoCloud />
      <CallToAction />
      <FooterNav />
    </div>
  );
}
