import React from 'react'
import { WebAppsDiag } from '../../components/Illustrations'
import WebsitePricing from '../../components/WebsitePricing'

export default function WebApps() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                Let us help you develop a high quality web application that fits your business
              </h2>
              <p className="mt-4 text-gray-500">
                Web applications have become an increasingly important tool for businesses. For example, to communicate with (new) employees, provide information and engage with target audiences or digitizing the internal workflow. Together we create high quality tailored web applications with interactive user interfaces that fully integrate with back-office systems.
              </p>
            </div>
            <div className="aspect-w-4 aspect-h-4">
              <WebAppsDiag className="object-center object-cover" />
            </div>
          </div>

          {/* <ServiceIncentives /> */}
        </div>
      </div>
      <WebsitePricing />
    </div>
  )
}
