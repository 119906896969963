import React from 'react'
import DashSamples from "../assets/img/dash-slates.webp";

export default function Feature() {
  return (
    <>
      {/* Feature section with screenshot */}
      <div className="relative bg-gray-50 pt-16 sm:pt-12 lg:pt-12">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-blue-600 uppercase">
              Software
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              No apps? No problem.
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              We can work out a possible solution that fit to your business.
            </p>
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <img className="" src={DashSamples} alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </>
  )
}
