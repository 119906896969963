import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid'
import "../assets/css/dunia.css";
import React from 'react'
import { NavLink } from 'react-router-dom';
import { Globe } from './animated/Splined';

export default function Hero() {
  return (

    <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-24 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">
                  Building sustainable solutions
                </span>
                <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-blue-700 to-blue-500 sm:pb-5">
                  for a better Future
                </span>
              </h1>
              <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                We transform lives though solutions that pushes you to
                attain your next milestone.
              </p>
              <NavLink
                to="about"
                className="mt-11 inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
              >
                <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-blue-700 to-blue-500 rounded-full">
                  More..
                </span>
                <span className="ml-4 text-sm">Check us out here</span>
                <ChevronDoubleRightIcon
                  className="ml-2 w-5 h-5 text-gray-500"
                  aria-hidden="true"
                />
              </NavLink>
              <div className="mt-10 sm:mt-12"></div>
            </div>
          </div>
          <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 place-content-stretch">
              <Globe className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none up-down" />

              {/* <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none up-down"
                src="https://www.pinclipart.com/picdir/big/163-1635292_planets-clipart-animated-globe-3d-model-of-earth.png"
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}