import React, { useRef, Suspense } from 'react'
// import Spline from '@splinetool/react-spline';
import { MobileData } from '../Illustrations';
import EarthVector from "../../assets/img/163-1635292_planets-clipart-animated-globe-3d-model-of-earth.webp";

const Spline = React.lazy(() => import('@splinetool/react-spline'));

// export function Circled(props) {
//     return (
//         <Spline scene="https://prod.spline.design/z4Ps1iD8-S4A7Iuu/scene.splinecode" {...props} />
//     )
// }

export function Globe(props) {
    const globe = useRef();

    const onLoad = (spline) => {
        const obj = spline.findObjectByName('Globe');
        // save it in a ref for later use
        globe.current = obj;
        const canvas = document.getElementById("globed-spline").getElementsByTagName("canvas");
        canvas[0].style = false;
    }

    return (
        <div id={"globed-spline"} >
            <Suspense fallback={<><img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none up-down"
                src={EarthVector}
                alt=""
            /></>}>
                <Spline scene="https://prod.spline.design/dLJ1jZaxTbx6QbQi/scene.splinecode" {...props}
                    onLoad={onLoad} />
            </Suspense>
        </div>
    )
}

export function Mobile(props) {
    const mobile = useRef();

    const onLoad = (spline) => {
        const obj = spline.findObjectByName('All');
        obj.setZoom = 1
        // save it in a ref for later use
        mobile.current = obj;
        // const canvas = document.getElementById("mobile-spline").getElementsByTagName("canvas");
        // canvas[0].style = "width:100%;height:100%";
    }

    return (
        <div id={"mobile-spline"} >
            <Suspense fallback={<MobileData className="object-center object-cover" />}>
                <Spline scene="https://prod.spline.design/RGjG0Qu60qDldizO/scene.splinecode" {...props}
                    onLoad={onLoad} />
            </Suspense>
        </div>
    )
}