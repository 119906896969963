import React from "react";
import PageHeader from "../components/PageHeader";
import ClientLogoCloud from "../components/ClientLogoCloud";
import { FooterNav } from "../components/Navigation";
import { EyeIcon, FlagIcon, } from "@heroicons/react/24/outline";
import CallToAction from "../components/CallToAction";

const info = [
  {
    name: "Vision",
    description:
      "Our vision is to become the preferred leader for solutions, despite the industry. We aim to empower and unleash the potential of all our customers.",
    icon: EyeIcon,
  },
  {
    name: "Mission",
    description:
      "Our mission is to make future relationships with our customers, to supply exceptional services that exceed expectations, by following business through innovation, advanced technology, and adding value.",
    icon: FlagIcon,
  },
];

export default function About() {
  return (
    <div className="bg-blue">
      <PageHeader page={"about"} />
      <section
        className="-mt-14 max-w-7xl mx-auto relative z-10 pb-4 px-4 sm:px-6 lg:px-8"
        aria-labelledby="about-heading"
      >
        <h2 className="sr-only" id="about-heading">
          About Us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8">
          {info.map((topic) => (
            <div
              key={topic.name}
              className="flex flex-col bg-white rounded-2xl shadow-xl"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <topic.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {topic.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {topic.description}
                </p>
              </div>
              {topic.href && (
                <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                  <a
                    href={topic.href}
                    className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                  >
                    More info <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              )
              }
            </div>
          ))}
        </div>
      </section>
      <ClientLogoCloud />
      <CallToAction />
      <FooterNav />
    </div>
  );
}
