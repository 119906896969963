import React, { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  CloudArrowUpIcon,
  CogIcon,
  DevicePhoneMobileIcon,
  MapPinIcon,
  ArrowPathIcon,
  ServerIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

function Services({ pageFilter }) {
  const [open, setOpen] = useState(false);
  let services = [
    {
      name: "Business Intelligence Services",
      description:
        "We provide Business Intelligence (B.I) consultancies to our clients aimed at fulfilling their business goals and progressing in competition. Nearly every company needs to use data-driven decision making.",
      icon: CloudArrowUpIcon,
      link: "business-intelligence",
    },
    {
      name: "Mobile Apps Development",
      description:
        "We provide end-to-end application design, integration, and administration solutions. Whether it's a game-changing enterprise-class solution or consumer-facing app, from design to delivery and continuous maintenance.",
      icon: DevicePhoneMobileIcon,
      link: "mobile-apps",
    },
    {
      name: "User Tailored Web Applications",
      description:
        "We provide web application development services, and we have the capacity to construct solid web apps that add value to our clients' day-to-day operations since we have excellent domain expertise and keep up with industry developments.",
      icon: ArrowPathIcon,
      landing: true,
      link: "web-apps",
    },
    {
      name: "GIS Solutions and Consultancy",
      description:
        "We provide geospatial solutions spanning from enterprise-wide spatially enabled business systems to personalized desktop applications tailored to each individual. Internal networks, intranets, and Internet thin client protocols are correctly exploited using a number of technologies depending on the needs of the business model.",
      icon: MapPinIcon,
      landing: true,
      link: "gis-solutions",
    },
    {
      name: "Digital Marketing",
      description:
        "At AthenaSL, we leverage our web marketing techniques to targeted to audience's specific needs as well as our client's company's goals. Our clients see an increase in conversion rate, user engagement and in this, we apply the correct digital marketing strategy.",
      icon: CogIcon,
      link: "digital-marketing",
    },
    {
      name: "ERP Solutions",
      description:
        "We develop scalable, enterprise-wide ERP systems to automate critical processes and provide key data insights across on-premise, mobile, and web-based platforms. We also provide system update and migration services to ensure data integrity by migrating legacy data and seamlessly integrating systems.",
      icon: ServerIcon,
      landing: true,
      link: "erp-solutions",
    },
  ];
  if (pageFilter === "landing") {
    services = services.filter((s) => s.landing);
  }
  return (
    <>
      <div className="relative bg-white py-10 sm:py-5 lg:py-10 mb-10 z-10">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-blue-600 uppercase">
            Get there on time
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Take us on
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Opportunities can be tapped even where it seems unfeasible.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {services.map((serve) => (
                <React.Fragment key={serve.name} >
                  <AsLinkedNav
                    route={serve.link}
                    cover={pageFilter ? false : true}
                    onClick={() => setOpen(true)}
                  >
                    <div className="pt-6">
                      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-blue-700 to-blue-500 rounded-md shadow-lg">
                              <serve.icon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                            {serve.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {serve.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </AsLinkedNav>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-10"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto relative w-screen max-w-[96vw]">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-3 shadow-xl">
                    <div className="relative mt-3 flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const AsLinkedNav = (props) => {
  return props.cover ? (
    <NavLink to={props.route} onClick={props.onClick}>
      {props.children}
      <div className="text-blue-600">View More</div>
    </NavLink>
  ) : (
    props.children
  );
}

export default Services;
