import React from 'react'
import { MapData } from '../../components/Illustrations'
// import ServiceIncentives from '../../components/ServiceIncentives'

export default function Gis() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                Professionals skilled for providing GIS solutions tailored for your specific business needs
              </h2>
              <p className="mt-4 text-gray-500">
                Being in the space as a GIS consulting company is not any simple task, however Polosoft has been in the field for several years and now we understand exactly the first thing a GIS project needs.
              </p>
              <p className="mt-4 text-gray-500">
                Geographic data systems consultants are the ones to manage digital mapping data and carry out the analyses to answer any and every geographical queries. Our key focus as a GIS consultancy service is to make maps for our clients, deduced from analytical knowledge. With years of experience and GIS services, we serve a variety of fields and industries.
              </p>
            </div>
            <div className="aspect-w-4 aspect-h-4">
              <MapData className="object-center object-cover" />
            </div>
          </div>
          {/* <ServiceIncentives /> */}
        </div>
      </div>
    </div>
  )
}
