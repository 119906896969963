import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../components/Logo";

export default function NotFound() {
  return (
    <>
      <div className="bg-white min-h-full flex flex-col lg:relative">
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col bg-white">
            <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16">
                <NavLink to="/" className="inline-flex">
                  <span className="sr-only">Athena SL</span>
                  <Logo className="h-10 w-auto text-blue-600" />
                </NavLink>
              </div>
              <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                <p className="text-5xl font-semibold text-blue-600 uppercase tracking-wide">
                  404 error
                </p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-6">
                  <NavLink
                    to={-1}
                    className="text-base font-medium text-blue-600 hover:text-blue-500"
                  >
                    <span aria-hidden="true"> &larr;</span>{" "}
                    Go back to Previous Page
                  </NavLink>
                </div>
                <div className="mt-6">
                  <NavLink
                    to="/"
                    className="text-base font-medium text-blue-600 hover:text-blue-500"
                  >
                    Go to home page
                    <span aria-hidden="true"> &rarr;</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1600705722738-d39380209f19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
