import React from 'react'
import { MarketIt } from '../../components/Illustrations'

const DigitalMarketing = () => {
  return (
    <div className="bg-blue-50">
      <div className="max-w-7xl mx-auto py-3 sm:px-2 sm:py-10 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                Digital Marketing
              </h2>
              <p className="mt-4 text-gray-500">
                Where others perceive content as king, we see it as the kingdom. The right content direct customers and helps satisfy them whenever they are looking for answers about your business. Content enables your business to lead your customers to solutions about their problems. We are experts in helping businesses establish how to take your online audience from potentials to organic growth
              </p>
            </div>
            <div className="aspect-w-4 aspect-h-4">
              <MarketIt className="object-center object-cover" />
            </div>
          </div>

          {/* <ServiceIncentives /> */}
        </div>
      </div>
    </div>
  )
}

export default DigitalMarketing