import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./Layout/Landing";
import About from "./pages/About";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import TnC from "./pages/TnC";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";
import BI from "./pages/services/BI";
import WebApps from "./pages/services/WebApps";
import Gis from "./pages/services/Gis";
import MobileApps from "./pages/services/MobileApps";
import DigitalMarketing from "./pages/services/DigitalMarketing";
import ErpSolutions from "./pages/services/ErpSolutions";
// import ChatPlugin from "./components/ChatPlugin";

function App() {
  return (
    <>
    {/* <ChatPlugin /> */}
    <Routes>
      <Route index element={<Landing />} />
      <Route path="about" element={<About />} />
      <Route path="services" element={<Service />}>
        <Route path="business-intelligence" element={<BI />} />
        <Route path="web-apps" element={<WebApps />} />
        <Route path="mobile-apps" element={<MobileApps />} />
        <Route path="gis-solutions" element={<Gis />} />
        <Route path="digital-marketing" element={<DigitalMarketing />} />
        <Route path="erp-solutions" element={<ErpSolutions />} />
      </Route>

      <Route path="contact" element={<Contact />} />

      <Route path="terms" element={<TnC />} />
      <Route path="privacy" element={<Privacy />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
    </>
  );
}

export default App;
