import React from 'react'

export default function Logo(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 740.9 646.98" {...props}>
      <path
        d="M518.36,647H394.51a10.33,10.33,0,0,1-8.91-5.11L198.88,320.57l-6-10.31a10.34,10.34,0,0,1,0-10.32l93.82-162.48a10.31,10.31,0,0,1,17.85,0L340.31,199a103.15,103.15,0,0,1,.12,103.37l-1.59,2.75L527.3,631.53A10.32,10.32,0,0,1,518.36,647Z"
        // transform="translate(-0.1 0)"
      />
      <path
        d="M370,647H10.43a10.32,10.32,0,0,1-8.94-15.47s178.43-309,178.74-309.37L253.73,448l-38.41,66.53A10.32,10.32,0,0,0,224.25,530h77.38Z"
        // transform="translate(-0.1 0)"
      />
      <path
        d="M730.67,647H606.83a10.3,10.3,0,0,1-8.85-5L368.19,247a249.61,249.61,0,0,1-8.94-233.77l.06-.13,4.53-8.92v0h0a7.61,7.61,0,0,1,13.38-.34L380,8.63,739.6,631.51A10.31,10.31,0,0,1,730.67,647Z"
        // transform="translate(-0.1 0)"
      />
    </svg>
  );
}
