import React from "react";

export default function LogoNameFull(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 2029.8 501.04" {...props}>
      <path d="M39.11,490.7A117.14,117.14,0,0,1,0,467l26.44-31.67C36.63,443.6,46,449.66,54.81,453.79a71.49,71.49,0,0,0,28.93,6.06c8,0,14-1.65,18.45-4.41a14.41,14.41,0,0,0,6.61-12.67v-.51a19.63,19.63,0,0,0-1.36-7.35,16.13,16.13,0,0,0-5.18-5.45,55.63,55.63,0,0,0-11.17-4.91c-4.63-1.9-10.9-3.54-18.8-5.72a197.67,197.67,0,0,1-26.16-7.9A67.71,67.71,0,0,1,26,400a45.07,45.07,0,0,1-12.81-15.8c-3-6.54-4.36-14.17-4.36-23.44v-.93a57.35,57.35,0,0,1,4.61-23,53,53,0,0,1,13.83-18.16c6-5.15,13.28-9,21.68-11.93a86.71,86.71,0,0,1,27.38-4.07,117.36,117.36,0,0,1,38.77,6.24,112.34,112.34,0,0,1,32.8,18.43l-22.77,32.8c-9.76-6.5-18.71-11.65-26.3-14.64a59.71,59.71,0,0,0-23-4.87C68.44,340.62,63,342,59.49,345a13.06,13.06,0,0,0-5.69,11.12v.6a15.06,15.06,0,0,0,7.69,13.72,59.16,59.16,0,0,0,12.07,4.94c5.22,1.65,11.8,3.3,19.76,5.49a206.51,206.51,0,0,1,26.08,8.79,77.66,77.66,0,0,1,19.48,11.52A44.76,44.76,0,0,1,151,417.05a56.27,56.27,0,0,1,3.84,21.41v.18c0,9.37-1.65,17.63-5.23,25.07A51.12,51.12,0,0,1,135,482.44a66.75,66.75,0,0,1-22.86,11.84,98.27,98.27,0,0,1-29.2,4.13A133.36,133.36,0,0,1,39.11,490.7Z" />
      <path d="M282.66,490.78a103.51,103.51,0,0,1-32-21,93.15,93.15,0,0,1-20.5-30.34,95,95,0,0,1-7.38-37.18v-.54a95.32,95.32,0,0,1,7.38-37.45,97.52,97.52,0,0,1,53-51.94c12.3-5.19,26-7.65,40.73-7.65,15,0,28.43,2.46,41,7.65a99,99,0,0,1,31.71,21.05,92.42,92.42,0,0,1,20.77,30.34,95.32,95.32,0,0,1,7.38,37.45v.55a95,95,0,0,1-7.38,37.17,103.21,103.21,0,0,1-21.05,30.89,97.3,97.3,0,0,1-32,21c-12.57,5.19-26,7.65-41,7.65A105,105,0,0,1,282.66,490.78Zm62.8-35.66a50.72,50.72,0,0,0,17-12.06,54.23,54.23,0,0,0,11.24-18.37,61.76,61.76,0,0,0,4.11-22.48v-.54a62.18,62.18,0,0,0-4.11-22.76,55.29,55.29,0,0,0-28.78-30.7,54.37,54.37,0,0,0-42.76,0,46.13,46.13,0,0,0-16.72,12.06,56.47,56.47,0,0,0-11.52,18.1,62.08,62.08,0,0,0-4.11,22.75v.55a63.21,63.21,0,0,0,4.11,22.75,57.76,57.76,0,0,0,11.52,18.36,53,53,0,0,0,38.65,16.45A55.07,55.07,0,0,0,345.46,455.12Z" />
      <path d="M508.8,498V305h46V458h96v40Z" />
      <path d="M734.47,479.16c-15-14.49-22.67-35.81-22.67-64V305h47V414.09c0,15.86,3.55,27.34,10.37,35q10.64,11.49,28.63,11.48c12,0,21.55-3.55,28.64-10.93,6.82-7.39,10.36-18.87,10.36-34.18V305h46V413.82c0,14.49-1.91,27.07-6,38a72.33,72.33,0,0,1-16.93,27.07c-7.38,7.38-16.67,12.85-27.32,16.68A113.08,113.08,0,0,1,796.76,501C770.26,501,749.5,493.66,734.47,479.16Z" />
      <path d="M962.8,345V305h163v40h-59V498h-46V345Z" />
      <path d="M1251.8,305V498h-46V305Z" />
      <path d="M1396.66,490.78a103.51,103.51,0,0,1-32-21,93.15,93.15,0,0,1-20.5-30.34,95,95,0,0,1-7.38-37.18v-.54a95.32,95.32,0,0,1,7.38-37.45,97.52,97.52,0,0,1,53-51.94c12.3-5.19,26-7.65,40.73-7.65,15,0,28.43,2.46,41,7.65a99,99,0,0,1,31.71,21.05,92.42,92.42,0,0,1,20.77,30.34,95.32,95.32,0,0,1,7.38,37.45v.55a95,95,0,0,1-7.38,37.17,103.21,103.21,0,0,1-21.05,30.89,97.3,97.3,0,0,1-32,21c-12.57,5.19-26,7.65-41,7.65A105,105,0,0,1,1396.66,490.78Zm62.8-35.66a50.72,50.72,0,0,0,17-12.06,54.23,54.23,0,0,0,11.24-18.37,61.76,61.76,0,0,0,4.11-22.48v-.54a62.18,62.18,0,0,0-4.11-22.76,55.29,55.29,0,0,0-28.78-30.7,54.37,54.37,0,0,0-42.76,0,46.13,46.13,0,0,0-16.72,12.06,56.47,56.47,0,0,0-11.52,18.1,62.08,62.08,0,0,0-4.11,22.75v.55a63.21,63.21,0,0,0,4.11,22.75,57.76,57.76,0,0,0,11.52,18.36,53,53,0,0,0,38.65,16.45A55.07,55.07,0,0,0,1459.46,455.12Z" />
      <path d="M1758.09,498,1668.8,381.16V498h-46V305h42.54l86.46,113V305h46V498Z" />
      <path d="M1914.11,490.7A117.14,117.14,0,0,1,1875,467l26.44-31.67c10.19,8.26,19.56,14.32,28.37,18.45a71.49,71.49,0,0,0,28.93,6.06c8,0,14-1.65,18.45-4.41a14.41,14.41,0,0,0,6.61-12.67v-.5a19.64,19.64,0,0,0-1.36-7.36,16.13,16.13,0,0,0-5.18-5.45,55.63,55.63,0,0,0-11.17-4.91c-4.63-1.9-10.9-3.54-18.8-5.72a197.67,197.67,0,0,1-26.16-7.9A67.71,67.71,0,0,1,1901,400a45.07,45.07,0,0,1-12.81-15.8c-3-6.54-4.36-14.17-4.36-23.44v-.93a57.35,57.35,0,0,1,4.61-23,53.11,53.11,0,0,1,13.82-18.16c6-5.15,13.29-9,21.69-11.93a86.71,86.71,0,0,1,27.38-4.07,117.36,117.36,0,0,1,38.77,6.24,112.34,112.34,0,0,1,32.8,18.43l-22.77,32.8c-9.76-6.5-18.71-11.65-26.3-14.64a59.71,59.71,0,0,0-23-4.87c-7.32,0-12.74,1.35-16.27,4.33a13.06,13.06,0,0,0-5.69,11.12v.6a14.32,14.32,0,0,0,1.92,8,14.47,14.47,0,0,0,5.77,5.76,59.16,59.16,0,0,0,12.07,4.94c5.22,1.65,11.8,3.3,19.76,5.49a206.51,206.51,0,0,1,26.08,8.79,77.66,77.66,0,0,1,19.48,11.52A44.76,44.76,0,0,1,2026,417.05a56.27,56.27,0,0,1,3.84,21.41v.18c0,9.37-1.65,17.63-5.23,25.07a51.12,51.12,0,0,1-14.6,18.73,66.75,66.75,0,0,1-22.86,11.84,98.27,98.27,0,0,1-29.2,4.13A133.36,133.36,0,0,1,1914.11,490.7Z" />
      <path d="M230.65,281l-81-199.38L91.24,225H152.8v56H.21L119.51,0h61.43L300.63,281Z" />
      <path d="M325.8,59V0h236V59h-85V280h-67V59Z" />
      <path d="M849.8,279V158.89l-105,33V279h-67V0h66V122.72l106-33.65V0h67V279Z" />
      <path d="M1242.8,0V57h-215V0Zm0,223v57h-215V111h135v57h-75v55Z" />
      <path d="M1569.54,280,1440.8,110.5V280h-66V0h61.34L1560.8,164V0h66V280Z" />
      <path d="M1725.59,281,1845.28,0h61.43L2026,281H1873.8V225h61.56L1877,81.62,1796,281Z" />
    </svg>
  );
}
